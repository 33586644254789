import React from 'react';
import { Code, Database, MessageCircle, ExternalLink } from 'lucide-react';

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white flex flex-col justify-center items-center p-4">
      <div className="max-w-4xl w-full space-y-12">
        <h1 className="text-6xl font-bold text-center mb-4">
          <span className="text-amber-400">En</span>
          <span className="text-orange-400">viral</span>
          <span className="text-amber-400">-FX</span>
        </h1>
        <p className="text-2xl text-center mb-8">
          Forex analysis tools and trading robots for the curious mind.
        </p>
        
        <div className="flex justify-center space-x-12 mb-12">
          <Feature icon={<Code size={32} />} text="EA Development" />
          <Feature icon={<Database size={32} />} text="Data-First Analysis" />
          <Feature icon={<MessageCircle size={32} />} text="Support, Chat" />
        </div>

        <div className="text-center mb-12">
          <h2 className="text-3xl font-semibold mb-4">Join the Enviral-FX Community</h2>
          <p className="text-xl mb-6">
            Come hangout with us on Telegram - we're trying to solve one of the most difficult puzzles.
          </p>
          <a
            href="https://t.me/+dCZB62fP9Fo0N2Yx"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center space-x-2 bg-gradient-to-r from-amber-500 to-orange-500 hover:from-amber-600 hover:to-orange-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105"
          >
            <span>Join Telegram Channel</span>
            <ExternalLink size={20} />
          </a>
        </div>
      </div>
    </div>
  );
};

const Feature = ({ icon, text }) => (
  <div className="flex flex-col items-center">
    <div className="bg-gradient-to-br from-amber-500 to-orange-500 text-gray-900 rounded-full p-4 mb-3">
      {icon}
    </div>
    <p className="text-lg text-center">{text}</p>
  </div>
);

export default App;